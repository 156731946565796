import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  Para,
  Large,
  Chat,
  Empty,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contact").toString()}</Language>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`).toString()}
              </Para>
              <a href="mailto:contato@flexconnect.app">
                <Chat>{t(`Let's Chat`).toString()}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Policy").toString()}</Title>
              <Large to="/politicas-de-privacidade">{t("Application Security").toString()}</Large>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Empty />
              <Language>{t("Address").toString()}</Language>
              <Para>Boituva/SP</Para>
              <Para>{t("Country").toString()}</Para>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language").toString()}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("pt")}>
                  <SvgIcon
                    src="brazil.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
